import { graphql } from 'gatsby';
import React from 'react';

import { Layout } from '../components/Layout/Layout';
import { Post } from '../components/Post/post';
import { SEO } from '../components/SEO';

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SEO />
      <div>
      </div>
    </Layout>
  );
};

export default IndexPage;
